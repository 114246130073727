import { ComponentStyleConfig } from '@chakra-ui/react'

import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

import { searchAnatomy } from '../../components/Search/anatomy'

export const Search: ComponentStyleConfig = {
  parts: searchAnatomy.keys,
  baseStyle: (props: StyleFunctionProps) => ({
    input: {},
    progress: {
      bg: 'chakra-body-bg',
      border: '1px',
      borderColor: 'inherit',
      borderRadius: 'xl',
      padding: '4',
      width: 'sm',
      marginTop: '1px'
    },
    errorMessage: {
      bg: 'chakra-body-bg',
      border: '1px',
      borderColor: 'inherit',
      borderRadius: 'xl',
      padding: '1',
      marginTop: '1px'
    },
    list: {
      bg: 'chakra-body-bg',
      border: '1px',
      borderColor: 'inherit',
      borderRadius: 'xl',
      padding: '1',
      marginTop: '1px'
    },
    item: {
      px: '4',
      py: '2',
      cursor: 'pointer',
      transition: 'background-color 220ms, color 220ms',
      _hover: {
        bg: mode(`brand.100`, `brand.400`)(props)
      },
      _highlighted: {
        bg: mode(`brand.100`, `brand.400`)(props)
      }
    }
  })
}
