import { b64EncodeUnicode } from '../../util/location'

export type InputOptionKeys = 'anchore' | 'aquasec' | 'blackduck' | 'cyclonedx' | 'dockerscout' | 'snyk' | 'spdx' | 'trivy' | 'veracode' | 'other'

export const inputOptions: Record<InputOptionKeys, { text: string; shortText?: string; type: string }> = {
  anchore: {
    text: 'Anchore',
    type: 'file'
  },
  aquasec: {
    text: 'Aquasec',
    type: 'file'
  },
  blackduck: {
    text: 'Black Duck',
    type: 'file'
  },
  cyclonedx: {
    text: 'CycloneDX',
    type: 'file'
  },
  dockerscout: {
    text: 'Docker Scout',
    type: 'file'
  },
  snyk: {
    text: 'Snyk',
    type: 'file'
  },
  spdx: {
    text: 'SPDX',
    type: 'file'
  },
  trivy: {
    text: 'Trivy',
    type: 'file'
  },
  veracode: {
    text: 'Veracode',
    type: 'file'
  },
  other: {
    text: "Don't know one or both? Mayhem can probably figure it out.",
    shortText: 'Other',
    type: 'file'
  }
}

const getStringFromKey = (key: string) => {
  if (key === 'other') {
    return 'unknown'
  }
  if (key in inputOptions) {
    return inputOptions[key as InputOptionKeys].text
  }
  return ''
}

export function generateUploadFilesStepTitle(keys: string[]) {
  let title = 'Please upload your'
  if (keys.length === 1) {
    title += ` ${getStringFromKey(keys[0])} file.`
  }
  if (keys.length === 2) {
    title += ` ${getStringFromKey(keys[0])} and ${getStringFromKey(keys[1])} files.`
  }
  if (keys.length > 2) {
    title += ` ${keys
      .slice(0, keys.length - 1)
      .map(getStringFromKey)
      .join(', ')} and ${getStringFromKey(keys[keys.length - 1])} files.`
  }
  return title
}

/**
 * Generate a unique key for an image reference using the registry, image name, tag and digest
 * the returned key is base64 encoded.
 * This is done since neither the registry, image name, tag or digest are guaranteed to be unique
 * instead we use a combination of all three to uniquely identify an image reference
 */
export function generateUniqueImageReferenceKey(
  registry: string | undefined | null,
  imageName: string,
  tag: string | undefined | null,
  hash: string | undefined | null
): string {
  const base = registry ? `${registry}/` : ''
  const name = tag ? `${imageName}:${tag}` : imageName
  const suffix = hash ? `@${hash}` : ''

  const key = `${base}${name}${suffix}`
  return b64EncodeUnicode(key)
}

/**
 * Decode the unique image reference key to get the image reference
 *
 */
export function decodeUniqueImageReferenceKey(key: string): string {
  const decodedKey = decodeURIComponent(atob(key))
  // We strip the image hash from the key
  const splitKey = decodedKey.split('@')
  return splitKey[0]
}
