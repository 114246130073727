import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['mapi'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMapiRequestLogs: build.query<GetMapiRequestLogsApiResponse, GetMapiRequestLogsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/request-log`,
          params: {
            endpoints: queryArg.endpoints,
            mapi_request_log_ids: queryArg.mapiRequestLogIds,
            methods: queryArg.methods,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            path: queryArg.path,
            per_page: queryArg.perPage,
            q: queryArg.q,
            response_status_codes: queryArg.responseStatusCodes,
            sort_by: queryArg.sortBy
          }
        }),
        providesTags: ['mapi']
      }),
      deleteMapiRequestLogs: build.mutation<DeleteMapiRequestLogsApiResponse, DeleteMapiRequestLogsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/request-log`,
          method: 'DELETE',
          params: {
            endpoints: queryArg.endpoints,
            mapi_request_log_ids: queryArg.mapiRequestLogIds,
            methods: queryArg.methods,
            path: queryArg.path,
            response_status_codes: queryArg.responseStatusCodes
          }
        }),
        invalidatesTags: ['mapi']
      }),
      getMapiRequestLogsFilters: build.query<GetMapiRequestLogsFiltersApiResponse, GetMapiRequestLogsFiltersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/request-log/filters`
        }),
        providesTags: ['mapi']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetMapiRequestLogsApiResponse = /** status 200
    Get mapi requests logs

    Args:
        response_status_codes: List of response status codes to filter by. If 0 is
            included in the list, it will include logs with no response status code.
        sort_by: Field to sort by, the default is MAPI_REQUEST_LOG_ID. We also provide
            the option to sort by non-2xx responses first.
     */ MapiRequestLogsPage
export type GetMapiRequestLogsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  endpoints?: string
  mapiRequestLogIds?: number[]
  methods?: ('GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'FAKEMETHOD')[]
  /** Offset in the result. */
  offset?: number
  orderBy?: 'asc' | 'desc'
  path?: string
  /** Number of elements per page. */
  perPage?: number
  q?: string
  responseStatusCodes?: number[]
  sortBy?: 'MAPI_REQUEST_LOG_ID' | 'RESPONSE_STATUS' | 'PATH' | 'METHOD' | 'REQUEST_TIME_MS' | 'NON_2XX_FIRST'
}
export type DeleteMapiRequestLogsApiResponse = /** status 200 Delete mapi request logs */ Ok
export type DeleteMapiRequestLogsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  endpoints?: string
  mapiRequestLogIds?: number[]
  methods?: ('GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'FAKEMETHOD')[]
  path?: string
  responseStatusCodes?: number[]
}
export type GetMapiRequestLogsFiltersApiResponse = /** status 200 Fetches the different filterable sets of values for the underlying mapi request
    log entries */ MapiRequestLogsFilters
export type GetMapiRequestLogsFiltersApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type HttpHeader = {
  key?: string
  value?: string
}
export type MapiRequestLog = {
  service_url: string
  path: string
  rendered_path: string
  method: string
  rendered_method: string
  request_headers: HttpHeader[]
  request_body?: string
  response_headers?: HttpHeader[]
  response_body?: string
  response_status?: number
  request_time_ms?: number
  mapi_request_log_id: number
  target_id: number
  run_id: number
}
export type MapiRequestLogsPage = {
  requests: MapiRequestLog[]
  count: number
}
export type ApiError = {
  message?: string
}
export type Ok = {
  result?: string
}
export type MapiRequestLogsEndpoint = {
  method: string
  path: string
}
export type MapiRequestLogsFilters = {
  response_status_codes: number[]
  endpoints: MapiRequestLogsEndpoint[]
}
